import { Controller } from "@hotwired/stimulus"

// Debug google tags
// https://tagassistant.google.com/

// Connects to data-controller="ga--checkout"
export default class extends Controller {
  static values = {
    step: Number
  }

  static targets = ['product', 'cart']

  connect() {
    if (this.hasCartTarget && this.hasStepValue && typeof gtag !== "undefined") {
      switch (this.stepValue) {
        case 1:
          gtag('event', 'view_cart', {
            currency: 'USD',
            value: this.subtotal,
            items: this.ga_item_list
          })
          return
        case 2:
          gtag('event', 'begin_checkout', {
            currency: 'USD',
            value: this.total,
            items: this.ga_item_list
          })
          return
        case 3:
          gtag('event', 'add_shipping_info', {
            currency: 'USD',
            value: this.total,
            items: this.ga_item_list
          })
          return
        case 4:
          gtag('event', 'add_payment_method', {
            currency: 'USD',
            value: this.total,
            items: this.ga_item_list
          })
          return
        case 5:
          gtag('event', 'purchase', {
            currency: 'USD',
            value: this.total,
            transaction_id: this.orderNumber,
            shipping: this.shipping,
            tax: this.tax,
            items: this.ga_item_list,
          })
          ttq.track('PlaceAnOrder', {
            content_type: 'product',
            contents: this.tt_item_list,
            currency: 'USD',
            value: this.total,
          })
          return
      }
    }
  }

  get total() {
    return this.cartTarget.dataset.cartTotal
  }
  
  get subtotal() {
    return this.cartTarget.dataset.subtotal
  }

  get tax() {
    return this.cartTarget.dataset.cartTax
  }

  get shipping() {
    return this.cartTarget.dataset.cartShipping
  }

  get orderNumber() {
    return this.cartTarget.dataset.cartNumber
  }

  get ga_item_list() {
    let items = this.productTargets.map((prod, i) => {
      return {
        item_id: prod.dataset.productId,
        item_name: prod.dataset.productName,
        price: prod.dataset.productPrice,
        qty: prod.dataset.productQty,
        item_category: prod.dataset.productMainType,
        item_category2: prod.dataset.productSubType,
      }
    })
    return items
  }

  get tt_item_list() {
    let items = this.productTargets.map((prod, i) => {
      return {
        content_id: prod.dataset.productId,
        content_name: prod.dataset.productName,
        price: prod.dataset.productPrice,
        quantity: prod.dataset.productQty,
      }
    })
    return items
  }
}
