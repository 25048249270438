import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ga--product-show"
export default class extends Controller {
  static targets = ['product']

  connect() {
    if (this.hasProductTarget && typeof window.gtag === "function") {
      gtag('event', 'view_item', {
        item_list_id: this.idValue,
        item_list_name: this.slugValue,
        items: this.ga_item_list,
      })
      ttq.track('ViewContent', {
        content_type: 'product',
        contents: this.tt_item_list,
      })
    }
  }

  get ga_item_list() {
    let items = this.productTargets.map((prod, i) => {
      return {
        item_id: prod.dataset.id,
        item_name: prod.dataset.name,
      }
    })

    return items
  }

  get tt_item_list() {
    let items = this.productTargets.map((prod, i) => {
      return {
        content_id: prod.dataset.id,
        content_name: prod.dataset.name,
      }
    })

    return items
  }
}
